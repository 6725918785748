import { useState, useEffect } from "react";
import Article from "../components/Article";

function Home() {
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const getArticles = async () => {
        const json = await (await fetch(`https://api.phillib.com`)).json();
        setArticles(json.articles);
        setLoading(false);
    };
    useEffect(() => {
        getArticles();
    }, []);
    return (
        <div>
            {loading ? (
                <div>
                    <span>Loading...</span>
                </div>
            ) : (
                <div>
                    <div>
                        <h1>Phillib!</h1>
                        <h2></h2>
                    </div>
                    <div>
                        {articles.map((article) => (
                            <Article
                                key={article.id}
                                title={article.title}
                                link={article.link}
                                name={article.name}
                                published={article.published}
                                tags={article.tags}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
