import PropTypes from "prop-types";

function Article({ id, name, title, published, link, tags }) {
    return (
        <div>
            <h3>
                <a href={link}>{title}</a>
            </h3>
            <p>
                {name} | {published} | {tags}
            </p>
        </div>
    );
}

Article.propTypes = {
    // id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    published: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    tags: PropTypes.string.isRequired,
};

export default Article;
