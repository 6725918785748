import React from "react";
import { BsFillInfoCircleFill, BsHouseDoorFill } from "react-icons/bs";

export const SidebarData = [
    {
        title: "Home",
        path: "/",
        icon: <BsHouseDoorFill />,
        cName: "nav-links",
    },
    {
        title: "About",
        path: "/About",
        icon: <BsFillInfoCircleFill />,
        cName: "nav-links",
    },
];
